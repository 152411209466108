<template>
  <div>
    <div class="text-h5">My Transaction History</div>

    <div class="content-area mt-5">
      <v-row align="center" justify="center">
        <v-col cols="12" md="3" v-for="item in items" :key="item.id">
          <v-card class="mx-auto">
            <v-card-text>
              <v-img height="250" :src="item.market.naskah.cover_url"></v-img>
              <div class="status-chips">
                <v-chip small dark
                  color="primary"
                  v-if="item.status == 0 || item.status == 1"
                >
                  Waiting for Payment
                </v-chip>
                <v-chip small dark class="ma-2" color="green" v-if="item.status == 2">
                  Success
                </v-chip>
                <v-chip small dark
                  class="ma-2"
                  color="red"
                  v-if="item.status == 3 || item.status == 4"
                >
                  Expired
                </v-chip>
              </div>
              <div class="mb-3 text-uppercase">{{ item.invoice }}</div>
              <p class="text-body-1 text--primary">
                {{ item.market.naskah.title }}
              </p>
              <div class="text-h4 text--primary">
                {{ item.price | currency }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="teal accent-4" @click="reveal = true">
                Payment Methods
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 text--primary">Origin</p>
                  <p>
                    late 16th century (as a noun denoting a place where alms
                    were distributed): from medieval Latin eleemosynarius, from
                    late Latin eleemosyna ‘alms’, from Greek eleēmosunē
                    ‘compassion’
                  </p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn text color="teal accent-4" @click="reveal = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      reveal: false,

      items: [],
      loadingData: false,
    };
  },
  methods: {
    getData() {
      this.loadingData = true;

      axios
        .get("customer/myTransaction", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          this.items = response.data.data.data;
          this.loadingData = true;
        })

        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
